<template>
  <div>
    <Modal :options="{width: '55vw',close: true}" @close="$emit('close')">
      <div class="title">Comprobante ${{ $helper.moneyFormat(comprobante.monto) }}</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-8">
            <div class="row form-group">
              <h3 class="col-sm-12">Información general</h3>
            </div>
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-4">Monto total</label>
              <div class="col-sm-8">
                <div class="form-control">${{ $helper.moneyFormat(comprobante.monto) }}</div>
              </div>
            </div>
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-4">Tipo de comprobante</label>
              <div class="col-sm-8">
                <div class="form-control">{{ tipo_comprobante }}</div>
              </div>
            </div>
            <div class="row form-group">
              <h3 class="col-sm-12">Conceptos</h3>
            </div>

            <table class="factura_conceptos">
              <thead>
                <tr>
                  <th>Concepto</th>
                  <th>Monto</th>
                  <th v-if="comprobante.tipo == 'factura'">Iva</th>
                  <th v-if="comprobante.tipo == 'factura'">Tipo factor</th>
                  <th v-if="comprobante.tipo == 'factura'">Tipo impuesto</th>
                  <th v-if="comprobante.tipo == 'factura'">Tasa o cuota</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="comprobante.tipo == 'factura'">
                    <template v-for="concepto in comprobante.conceptos">
                      <tr v-for="impuesto in concepto.impuestos" :key="impuesto.id">
                        <td>{{ concepto.concepto }}</td>
                        <td>${{ $helper.moneyFormat(concepto.monto) }}</td>                        
                        <td>${{ $helper.moneyFormat(impuesto.importe) }}</td>
                        <td class="text-center">{{ ( !impuesto.tipo_factor ? 'N/A' : impuesto.tipo_factor )}}</td>
                        <td class="text-center">{{ (!impuesto.impuesto ? 'N/A' : impuesto.impuesto ) }}</td>
                        <td class="text-center">{{ (!impuesto.tasa_cuota ? 'N/A' : impuesto.tasa_cuota ) }}</td>
                      </tr>
                    </template>
                </template>
                <template v-else>
                  <tr v-for="concepto in comprobante.conceptos" :key="concepto.id" >
                    <td>{{ concepto.concepto }}</td>
                    <td>${{ $helper.moneyFormat(concepto.monto) }}</td>
                  </tr>
                </template>
              </tbody>
            </table>

            <Comentario :viatico="viatico" :comprobante="comprobante" origen="constelacion" @update="$emit('update')" />
          </div>
          <div class="col-sm-4">
            <div class="row">
              <h3 class="col-sm-12">Documentos</h3>
            </div>
            <div class="row">
              <div v-if="['jpg','jpeg','png','bmp'].indexOf(documento.extension) != -1" class="col-sm-12">
                <img class="documento_img mb-3" :src="contenido" />
                <button class="btn btn-secondary" @click="descargar_documento">Descargar Imágen</button>
              </div>
              <div v-else class="col-sm-12">
                <embed class="documento_pdf" :src="contenido" type="application/pdf" />
                <button class="btn btn-secondary" @click="descargar_documento">Descargar PDF</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="$emit('close')">Cerrar</button>
            <button class="btn btn-danger" @click="eliminar_comprobante">Eliminar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import Comentario from '@/apps/tesoreria/pages/Tesoreria/Viatico/Info/Comprobante/Revisar/Comentario'

import api from '@/apps/constelacion/api/tesoreria'

export default {
  components: {
    Modal, Comentario
  }
  ,props: {
    viatico: {
      type: Object,
      default() {
        return {
          id: null,
          comentarios: []
        }
      }
    },
    comprobante: {
      type: Object,
      required: true,
      default() {
        return {
          id: null,
          comentarios: []
        }
      }
    }
  }
  ,data() {
    return {
      binario: null,
      contenido: null
    }
  }
  ,mounted() {
    this.visualizar_imagen();
  }
  ,methods: {
    async visualizar_imagen() {
      try {
        api.visualizar_documento(this.comprobante.viatico_id, this.documento.id)
        .then(res => {
          this.binario = res.data;
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          )

          this.contenido = 'data:'+this.documento.mime+';base64,'+base64;
        })
        this.$log.info('comprobante prop ', this.comprobante);
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,descargar_documento() {
      this.$helper.descargar_documento(this.binario, this.documento.nombre_original);
    }
    ,eliminar_comprobante() {
      this.$emit('close');
      this.$emit('eliminar',this.comprobante);
    }
  }
  ,computed: {
    tipo_comprobante() {
      return this.comprobante.tipo == 'nota' ? 'Nota' : 'Factura';
    }
    ,documento() {
      if (this.comprobante.tipo == 'nota') 
        return this.comprobante.documentos[0];
      else {
        let documento = null;

        for(let i=0; i<this.comprobante.documentos.length; i++) {
          if (this.comprobante.documentos[i].extension == 'pdf')
            documento = this.comprobante.documentos[i];
        }

        return documento;
      }
    }
  }
}
</script>

<style lang="scss">
.documento_img {
  width: 100%;
}

.documento_pdf {
  width: 100%;
  height: 400px;
}

.factura_conceptos {
  width: 100%;

  thead {
    tr {
      th {
        background-color: #3b3b3b;
        padding: 5px 10px;
      }
    }
  }
}
</style>